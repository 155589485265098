/* Site-wide CSS */

:root {
  --primary-purple: #6a1b9a;
  --light-purple: #9c4dcc;
  --extralight-purple: #eed1ff;
  --dark-purple: #38006b;
  --primary-pink: #f48fb1;
  --light-pink: #ffc1e3;
  --dark-pink: #bf5f82;
  --white: #fff;
  --black: #000;
  --grey: #444;
  --light-grey: #999;
  --green: #09af00;
  --red: #e02d00;
}

body, html {
  padding: 0;
  margin: 0;
  font-family: 'Open Sans', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  font-family: 'Playfair Display', sans-serif;
}

h1 {
  font-size: 3rem;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 36px;
}

h4 {
  font-size: 24px;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

img {
  max-width: 100%;
  display: block;
}

.break {
  flex-basis: 100%;
  height: 0;
}


.sans-serif {
  font-family: 'Open Sans', sans-serif;
}

/* Page flow */

nav {
  height: 100px;
  width: 100%;
  color: var(--black);
  z-index: 1;
}

nav .logo {
  width: auto;
  height: 100px;
}

nav .container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

nav h1 {
  padding: 0;
  margin: 0;
  font-size: 3.5rem;
  font-weight: bold;
}

nav h1 span {
  display: inline-block;
  height: unset;
  width: 0px;
  vertical-align: top;
  overflow-x: hidden;
  transition: .3s all;
}

header {
  background-color: var(--white);
  padding: 25px 15px;
  color: var(--grey);
}

header .card {
    max-width: 400px;
    border-radius: 16px;
    margin: 0 auto;
    box-shadow: 0px 0px 12px -3px;
    width: 100%;
    overflow: hidden;
    z-index: 2;
}

.mePic {
  width: 100%;
  max-width: 500px;
  display: block;
}


.headerText {
  display: block;
  margin: 15px auto;
  padding: 30px;
  position: relative;
}

.headerText h1, .headerText p {
  margin: 0;
  max-width: 100%;
  font-weight: bold;
}



/* Chat CSS */

.chatBoxExpandButton{
  position: fixed;
  width: 50px;
  height: 50px;
  border-radius: 50px;
  bottom: 5%;
  right: 5%;
  background-color: var(--grey);
  color: var(--white);
  display: table;
  cursor: pointer;
  box-shadow: 0px 3px 14px -4px var(--black);
  transition: .3s all;
  z-index: 50;
}

.chatBoxExpandButton:hover {
  box-shadow: 0px 3px 22px -6px var(--black);
  opacity: .9;
}

.chatBoxExpandButton .material-icons {
  display: table-cell;
  margin: 0 auto;
  vertical-align: middle;
  text-align: center;
}

.react-chatbot-kit-chat-container {
  transition: .3s all;
  transform-origin: 90% 100%;
  transform: scaleY(0);
  position: fixed !important;
  right: 5%;
  bottom: 150px;
  z-index: 2;
}

.react-chatbot-kit-chat-container.shown {
  transform: scaleY(1);
}

.react-chatbot-kit-chat-btn-send {
  cursor: pointer;
}

.botAvatar {
  width: 50px;
  height: 50px;
  margin-right: 10px;
  border-radius: 50px;
}

.optionButton {
  font-family: 'Poppins', sans-serif;
  border-radius: 50px;
  border: 2px solid var(--light-grey);
  color: var(--grey);
  background-color: unset;
  outline: none;
  padding: 4px 15px;
  cursor: pointer;
  transition: .3s all;
  display: inline-block;
  margin: 3px;
}

.optionButton:hover {
  background-color: var(--light-grey);
  border-color: var(--grey);
  color: var(--white);
}

.yesNoButtons {
  text-align: center;
}

.yesNoButtons button {
  width: 40%;
  display: inline-block;
  padding: 15px;
  margin: 2px;
  font-family: 'Poppins', sans-serif;
  border: none;
  cursor: pointer;
  transition: .3s all;
}

.yesNoButtons button:hover {
  opacity: .9;
}

.yesNoButtons .yes {
  background-color: var(--green);
  color: #fff;
}

.yesNoButtons .no {
  background-color: var(--red);
  color: #fff;
}

.contact a {
  text-decoration: none;
}

.contact a button {
  border: 3px solid;
  width: 100%;
  padding: 8px;
  border-radius: 8px;
  margin-bottom: 5px;
  cursor: pointer;
  font-family: 'Poppins', sans-serif;
  line-height: 1.3;
}

.contact .tinder {
  border-color: #FF655B;
  background-color: #FF5864;
  color: var(--white);
}

.contact .telegram {
  background-color: var(--dark-purple);
  color: #c8f2ff;
}

.contact .text {
  background-color: #35cc5b;
  color: #fff;
}


.quick-facts {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  cursor: pointer;
}

.quick-facts div {
  scroll-snap-align: center;
  width: 400px;
  max-width: 100%;
}

.quick-facts::-webkit-scrollbar {
  background-color: var(--grey);
  max-height: .5rem;
}

.quick-facts::-webkit-scrollbar-thumb {
  background-color: var(--white);
  width: 30%;
  border-radius: 15px;
}

@media screen and (max-width: 768px) {
  .quick-facts {
    scroll-snap-type: x mandatory;
  }
}